import React, { useEffect, useState } from "react";
import { Layout, queryLoad } from "../components";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import {
  makeStyles,
  Theme,
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import { GoogleLogin } from "react-google-login";
import { useCookies } from "react-cookie";
import {
  isBrowser,
  isLoggedIn,
  isLocalhost,
  getRefreshToken,
  setToken,
  setRefreshToken,
  getToken,
} from "../common/utils/auth";

import { AUTHENTICATE, REFRESH_LOGIN } from "../common/models/authenticate";
import { RefreshLogin } from "../common/models/types/RefreshLogin";

import { GET_GOOGLE_USER_PROFILE_INFORMATION } from "../common/models/profileinformation";

/* import ScLogo from "../images/sc-logo.png"; */
import AppLogo from "../images/ldp-logo.png";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import styles from "../styles/login.module.css";

const Login = () => {

  const [cookies, , removeCookie] = useCookies();
  const [googleResponse, setGoogleResponse] = useState<any>();
  const [refreshLoginLoading, setRefreshLoginLoading] = useState<boolean>(false);


  const [authenticate, {
    data: authenticateData }] = useLazyQuery(AUTHENTICATE);

  const [refreshLogin] = useMutation<RefreshLogin>(REFRESH_LOGIN);

  useEffect(() => {
    if (isBrowser) document.title = "Login";
    console.log("Version: " + process.env.GATSBY_VERSION);
    //console.log(cookies, cookies?.prevPage);
    if (isLoggedIn()) {
      continueRender();
    } else {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        setRefreshLoginLoading(true);
        refreshAuthentication(refreshToken);
      }
    }
  }, []);

  const continueRender = () => {
    if (isBrowser && cookies?.prevPage) {
      const prevPage = cookies?.prevPage + "";

      removeCookie("prevPage", {
        domain: isLocalhost ? "localhost" : ".leaddeliveryplatform.com",
      });
      document.location.href = prevPage;
    } else {
      navigate("/");
    }
  };

  const refreshAuthentication = (refreshToken: string) => {
    refreshLogin({
      variables: {
        refreshToken: refreshToken
      }
    }).then(response => {
      const newToken = response?.data?.RefreshLogin?.id_token;
      if (newToken) {
        setRefreshLoginLoading(false);
        setToken(newToken);
        continueRender();
      }
    });
  };

  const responseFailedGoogle = (response: any) => {
    console.log("FAILED", response);
  };

  const responseSuccessGoogle = (response: any) => {
    //console.log("SUCCESS", response);
    setGoogleResponse(response);
    setRefreshLoginLoading(true);
    if (isLocalhost) {
      authenticate({
        variables: { oneTimeCode: response.code, customRedirectURI: "http://localhost:8101" },
      });
    } else if (window.location.hostname == "www.dev.leaddeliveryplatform.com" || window.location.hostname == "dev.leaddeliveryplatform.com") {
      authenticate({
        variables: { oneTimeCode: response.code, customRedirectURI: "https://dev.leaddeliveryplatform.com" },
      });
    } else {
      authenticate({
        variables: { oneTimeCode: response.code },
      });
    }
  };

  useEffect(() => {
    if (authenticateData) {
      if (authenticateData.Authenticate.refresh_token) {
        setRefreshToken(authenticateData.Authenticate.refresh_token);
      }

      if (authenticateData.Authenticate.id_token) {
        setToken(authenticateData.Authenticate.id_token, (Date.now() / 1000) + (Number(authenticateData.Authenticate.expires_in) || 1800));
        continueRender();
      }
    }
  }, [authenticateData]);

  return (
    <Container component="main" className={styles.mainContainer}>
      {queryLoad([!!refreshLoginLoading], []) || (
        <Paper elevation={3} className={styles.logoPaper}>
          <img src={AppLogo} className={styles.logoImage} />
          <Typography variant="h3" className={styles.logoText}>Lead Delivery Platform</Typography>
          <div className={styles.googleBtn}>
            <GoogleLogin
              clientId="6993853457-bggc93g5cbqsikst46uon22gm4c2gl2u.apps.googleusercontent.com"
              buttonText="Login with Google"
              onSuccess={responseSuccessGoogle}
              onFailure={responseFailedGoogle}
              prompt="consent"
              accessType="offline"
              responseType="code"
            />
          </div>
        </Paper>

      )}
    </Container>
  );
};

export default Login;
